<template>
  <div>
      <h3 class="primary">{{title}}</h3>
      <span v-if="status=='planned'">Період голосування: {{plannedDate}}</span>
      <span v-if="status=='active'">Голосування триває до {{activeDate}}</span>

      <h5 v-if="status=='finished'" class="elections_timer">{{finishedDate}}</h5>
      <h5 v-else class="elections_timer">До {{(status=='active')?'завершення':'початку'}} голосування залишається: {{timeLeft}}</h5>
      
      <div  v-if="isViewDetail && election.my_vote_count">
        <div class="callout small cabinet elections_vote_isset">
          Дякуємо! Ваш голос враховано.
        </div>
        <div
          class="button hollow close"
          data-close=""
          style="width: 100%"
          title="Повернутись до списку виборів"
          @click="$emit('hideDetail')"
        >
          Повернутись до списку виборів
        </div>
      </div>

      <div class="elections_actionButton" v-if="!isViewDetail">
        <div :title="buttonText" class="button m-t" @click="openDetail">{{buttonText}}</div>
      </div>

      <div v-if="status=='finished' && isViewDetail">
        <hr>
        <h5>Кількість виборців, що прийняли участь в голосуванні: {{election.vote_count}}</h5>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import API from '@/api.js';

export default {
  name: 'electionsListCard',
  props:['election','status','isViewDetail'],
  data:function(){
    return {
      now:Math.ceil(new Date().getTime()/1000)+this.$store.state.serverTimeDelta,
    }
  },
  computed:{
    title:function(){
      return (this.election.type==1)?'Вибори студентського ректора':'Вибори студентського декана/директора ' + this.election.dep_abbr
    },
    buttonText:function(){
      let text = 'Прийняти участь в голосуванні';
      if(this.status != 'active' || this.election.my_vote_count){ text = 'Переглянути детальну інформацію'; }
      return text
    },
    activeDate:function(){
      return  moment(new Date(this.election.end_date*1000)).format('LLL')
    },
    plannedDate:function(){
      let s=moment(new Date(this.election.start_date*1000)).format('LLLL')
      let e=moment(new Date(this.election.end_date*1000)).format('LLL')
      return (s==e)?s:s+' - '+e;
    },
    finishedDate:function(){
      let s=moment(new Date(this.election.start_date*1000)).format('LL')
      let e=moment(new Date(this.election.end_date*1000)).format('LL')
      return (s==e)?s:s+' - '+e;
    },
    timeLeft:function(){
      let delta = this.election.start_date - this.now;

      if(this.status=="active"){
        delta = this.election.end_date - this.now;
      }

      if(delta==0){
        setTimeout(()=>{
          location.reload()
        },2000)
      }
      if(delta<0){
        return '---';
      }

      let d = Math.floor(delta/86400)
          d = d + ' ' + this.num2str(d,['день','дні','днів'])
      let h = Math.floor(delta%86400/3600)
          h = ((h<10)?'0'+h:h) + ' ' + this.num2str(h,['година','години','годин'])
      let m = Math.floor(delta%86400%3600/60)
          m = ((m<10)?'0'+m:m) + ' ' + this.num2str(m,['хвилина','хвилини','хвилин'])
      let s = Math.floor(delta%86400%3600%60)
          s = ((s<10)?'0'+s:s) + ' ' + this.num2str(s,['секунда','секунди','секунд'])

      return [d, h, m, s].join(' ');
    }
  },
  methods:{
    num2str(n, text_forms) {
        n = Math.abs(n) % 100; var n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    },
    openDetail() {
      if(this.status == 'active'){
        let api = new API(this.$store.state.key);
        api.view({
          election_id: this.election.id,
        });
      }
      this.$emit('showDetail');
    },
  },
  mounted(){
    setInterval(()=>{
      this.now = Math.ceil(new Date().getTime()/1000)+this.$store.state.serverTimeDelta;
    },1000)
  }
}
</script>
