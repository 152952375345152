import Vue from 'vue';
import App from './App.vue';

/*moment js*/
const moment = require('moment')
require('moment/locale/uk')
Vue.use(require('vue-moment'), {
    moment
})

import store from './store';

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
