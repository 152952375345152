import axios from 'axios'

var querystring = require('querystring');

class API{
  constructor(userKey) {
    this.userKey = userKey
    this.axios = axios;
  }

  config(){
    return {
      baseURL:process.env.VUE_APP_API_URL,
      timeout:15000,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-key': this.userKey || 0
      }
    }
  }

  /*main*/

  getAvailableElections(data){
    return new Promise((resolve, reject) => {
      this.axios.post('/elections/available',querystring.stringify(data),this.config()).then((response) => {
          if(response.status==200){
            if(response.data.status=="ok"){
              resolve(response.data.data);
            }else{
              reject({
                error:'bad response status',
                code:'01',
                data:response
              });
            }
          }else{
            reject({
              error:'bad response',
              code:'02',
              data:response
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  
  vote(data){
    return new Promise((resolve, reject) => {
      this.axios.post('/vote',querystring.stringify(data),this.config()).then((response) => {
          if(response.status==200){
            if(response.data.status=="ok"){
              resolve(response.data.data);
            }else{
              reject({
                error:'bad response status',
                code:'01',
                data:response
              });
            }
          }else{
            reject({
              error:'bad response',
              code:'02',
              data:response
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  view(data){
    return new Promise((resolve, reject) => {
      this.axios.post('/elections/view',querystring.stringify(data),this.config()).then((response) => {
          if(response.status==200){
            if(response.data.status=="ok"){
              resolve(response.data.data);
            }else{
              reject({
                error:'bad response status',
                code:'01',
                data:response
              });
            }
          }else{
            reject({
              error:'bad response',
              code:'02',
              data:response
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}

export default API