<template>
  <div>
    <div v-if="activeElection">
      <electionsActive
        @hideDetail="hideDetail"
        :election="activeElection"
        :status="status"
      />
    </div>
    <div v-else-if="elections.length">
      <electionsListCard
        class="callout paddings"
        v-for="e in elections"
        :status="status"
        :election="e"
        v-bind:key="e"
        @showDetail="showDetail(e)"
        @hideDetail="hideDetail"
      />
    </div>
    <div v-else class="column">
      <p class="elections_empty_list"><b>Інформація для відображення відсутня</b></p>
    </div>
  </div>
</template>

<script>
import electionsListCard from '@/components/electionsListCard.vue'
import electionsActive from '@/components/electionsActive.vue'

export default {
  name: 'electionsList',
  props:['elections','status'],
  components:{
    electionsListCard,
    electionsActive,
  },
  data:function(){
    return {
      activeElection:null,
    }
  },
  methods:{
    showDetail:function(election){
      this.$emit('showDetail');
      this.activeElection=election;
    },
    hideDetail:function(){
      this.$emit('hideDetail');
      this.activeElection=null;
    },
  }
}
</script>
