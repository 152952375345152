<template>
  <div>
    <div class="elections_tabs callout" v-if="!detailActive">
      <div :class="{active:activeTab=='active'}" @click="activeTab='active'">Активні<span class="elections_sm_hide"> вибори</span></div>
      <div :class="{active:activeTab=='planned'}" @click="activeTab='planned'">Заплановані</div>
      <div :class="{active:activeTab=='finished'}" @click="activeTab='finished'">Завершені</div>
    </div>
    <electionsList
      :elections="elections[activeTab]"
      :status="activeTab"
      @showDetail="detailActive=true"
      @hideDetail="detailActive=false"
    />
  </div>
</template>

<script>
import electionsList from '@/components/electionsList.vue'

export default {
  name: 'pageMain',
  props:['elections'],
  components:{
    electionsList,
  },
  data:function(){
    return {
      activeTab:'finished',
      detailActive:false,
    }
  },
  mounted(){
    if(this.elections.planned.length){this.activeTab = 'planned';}
    if(this.elections.active.length){this.activeTab = 'active';}
  }
}
</script>
