import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex)

export default new Vuex.Store({
	state:{
		serverUrl:process.env.VUE_APP_SERVER_URL,
		apiUrl:process.env.VUE_APP_API_URL,
		key:null,
		serverTimeDelta:0,
	},
	mutations: {
		setKey (state,data) {
			state.key=data
		},
		setServerTimeDelta (state,data) {
			state.serverTimeDelta=data
		}
	}
})
