<template>
  <div>
    <appLoad v-if="load"/>
    <div v-else-if="loadElections_fail" class="elections_error">
      <b>Не вдалось отримати список доступних виборів.</b><br>
      {{loadElections_fail}}
      <div>
        <a href="https://cabinet.sumdu.edu.ua/" title="Повернутися на головну" class="button m-t">Повернутися на головну</a>
        <div title="Оновити сторінку" class="button m-t" style="margin-left:5px;" @click="reload">Оновити сторінку</div>
      </div>
    </div>
    <div v-else>
      <pageMain :elections="elections"/>
    </div>
  </div>
</template>

<script>
var key = new URL(location.href).searchParams.get("key");

import API from '@/api.js';
var api = new API(key);

import appLoad from './components/appLoad.vue';
import pageMain from './components/pageMain.vue';

export default {
  name: 'app',
  data(){
    return {
      key:key,
      load:1,
      elections:null,
      loadElections_attempt:0,
      loadElections_fail:false,
    }
  },
  components: {
    appLoad,
    pageMain,  
  },
  methods:{
    loadElections:function(){
      api.getAvailableElections().then(data => {
        this.elections=data.elections;
        this.load=false;

        this.$store.commit('setKey',key);
        this.$store.commit('setServerTimeDelta',data.server_time-Math.ceil(new Date().getTime()/1000));

        window.$('.loadHide').removeClass('loadHide')
        window.$('#person-photo img').attr('src',"https://cabinet.sumdu.edu.ua/api/getPersonPhoto?key="+key)
        window.$('#fio').text(data.student.surname + "\n" + data.student.name + "\n" + data.student.patronymic)
      }).catch((e) => {
        if(this.loadElections_attempt<5){
          this.loadElections_attempt++;
          setTimeout(()=>{
            this.loadElections()
          },300)
        }else{
          this.load=false;
          if(e.error=="bad response status"){
            if(e.data.data.description){
              location.href = 'https://cabinet.sumdu.edu.ua/?charset=utf-8&error='+encodeURI(e.data.data.description);
            }else{
              location.href = 'https://cabinet.sumdu.edu.ua/?charset=utf-8&error='+encodeURI('Сервер повернув неочікувану відповідь. Спробуйте оновити сторінку через декілька секунд.');
            }
          }else{
            location.href = 'https://cabinet.sumdu.edu.ua/?charset=utf-8&error='+encodeURI('Сервер не відповідає на запити ('+e.toString()+'). Спробуйте оновити сторінку через декілька секунд.');
          }
          
        }
      })
    },
    reload:function(){
      location.reload()
    }
  },
  mounted(){
    if(key){
      this.loadElections();
    }else{
      location.href = 'https://cabinet.sumdu.edu.ua/?charset=utf-8&error='+encodeURI('Не вказано ключ доступу');
    }
  }
}
</script>

<style>
  .elections_error{
    text-align: center;
    color:red;
    margin:25px auto;
  }

  .elections_tabs{
    display: flex
  }
  .elections_tabs>div{
    width: 33.333%;
    cursor:pointer;
    text-align: center;
    line-height: 35px;
    height: 35px;
  }
  .elections_tabs>div:first-child{
    border-radius:6px 0 0 6px;
  }
  .elections_tabs>div:last-child{
    border-radius:0 6px 6px 0;
  }
  .elections_tabs>div:hover{
    background-color: rgba(68,78,139,.4);
  }
  .elections_tabs>div.active{
    color:#fefefe;
    background-color: #444e8b;
  }
  .elections_vote,
  .elections_actionButton {
    display: flex;
    justify-content: flex-end; 
  }
  
  .elections_candidate_wrap{
    min-height: 165px;
    box-shadow:none;
  }
  .elections_vote_against_wrap{
    box-shadow:none;
  }
  .elections_candidate{
    min-height: 158px;
  }
  .elections_candidate>img{
  width: 128px;
  float:left;
  margin: 10px 15px 15px 0;
  height: 128px;
  }

  .elections_candidate_description{
    min-height:158px;
  }
  .elections_candidate_description>p{
    white-space:pre-wrap;
  }

  .elections_appealsLink{
    word-wrap:break-word;
  }

  .elections_timer{
    margin-top:10px;
  }

  .elections_candidate_links>a.button{
    margin-right:10px;
  }
  .elections_candidate_links>a.button:last-child{
    margin-right:0px;
  }

  .elections_vote_isset{
    background: #3adb76 !important;
    text-align: center;
  }
  .elections_vote_against{
    background: #ec5840 !important;
  }

  .elections_empty_list{
    text-align: center;
  }

  @media screen and (max-width: 400px){
    .elections_sm_hide{
      display:none;
    }
  }
  

</style>