<template>
  <div class="callout paddings">
      <electionsListCard :status="status" :election="election" :isViewDetail="true" @hideDetail="$emit('hideDetail')"/>
      <hr>
      <candidatesList :election="election" :status="status"/>
      <div class="button hollow close m-t" data-close="" title="Повернутись до списку виборів" style="margin-right:5px" @click="$emit('hideDetail')">Повернутись до списку виборів</div>
  </div>
</template>

<script>
import electionsListCard from '@/components/electionsListCard.vue'
import candidatesList from '@/components/candidatesList.vue'

export default {
  name: 'electionsActive',
  props:['election','status'],
  components:{
    electionsListCard,
    candidatesList,
  }
}
</script>
