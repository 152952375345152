<template>
  <div>
      <div v-for="a in appeals" v-bind:key="a.id" style="margin-bottom:10px; padding-left:10px; border-left:5px solid #444e8b">
        <div><b>Заголовок:</b> {{a.title}}</div>
        <div><b>Опис:</b> {{a.description}}</div>
        <div v-if="a.url">
          <b>Посилання на документ:</b> <a class="elections_appealsLink" target="_blank" :href="a.url">{{a.url.substr(0,75)}}{{(a.url.length>75)?'...':''}}</a>
        </div>
        <div><b>Вплив на результат:</b> {{(a.impact>0)?'+'+a.impact:a.impact}}</div>
      </div>
  </div>
</template>

<script>
  
export default {
  name:'electionDetail',
  props:['appeals'],
  data () {
    return {

    }
  },
  methods: {

  },
  computed:{

  },
  created:function(){
    
  }
}
</script>