<template>
  <div>
      <div v-if="candidates.length">
        <h4>Кандидати на посаду:</h4>
        <div class="callout paddings elections_candidate_wrap" v-for="c in candidates" v-bind:key="c">
          <div class="elections_candidate">
            <img :src="c.photo">
            <div class="elections_candidate_description">
              <h4>{{c.surname}} {{c.name}} {{c.patronymic}}</h4>
              <p>{{c.description}}</p>
            </div>
            <p class="elections_candidate_links">
              <a
                v-if="c.document_url"
                target="_blank"
                rel="nofollow noopener"
                :href="c.document_url"
                class="button hollow close m-t"
              >Передвиборча програма</a>
              <a
                v-if="c.video_url" 
                target="_blank"
                rel="nofollow noopener"
                :href="c.video_url"
                class="button hollow close m-t"
              >Відео-презентація</a>
            </p>
          </div>
          <div v-if="status=='active' && !election.my_vote_count" class="elections_vote">
            <div
              title="Віддати голос за цього кандидата"
              class="button m-t"
              @click="vote(c.id,1)"
            >Віддати голос за цього кандидата</div>
            <div
              v-if="candidates.length==1"
              title="Віддати голос проти цього кандидата"
              style="margin-left:10px;"
              class="button m-t elections_vote_against"
              @click="vote(c.id,-1)"
            >Віддати голос проти цього кандидата</div>
          </div>
          <div v-if="status=='finished'">
            <hr>
            <h5>Кількість голосів за кандидата: {{c.vote_count}}<br>{{(candidates.length==1)?'Проти: '+c.vote_against_count:''}}</h5>
          </div>
          <div v-if="status=='finished' && c.appeals.length">
            <hr>
            <h5>Інформація про постанови ВК:</h5>
            <appealsDetail :appeals="c.appeals"/>
          </div>
          <div v-if="status=='finished' && (c.appeals.length || candidates.length==1)">
            <hr>
            <h5>Результат: {{c.result_votes}}</h5>
          </div>
          <hr>
        </div>
        <div v-if="status=='active' && candidates.length>1 && !election.my_vote_count" class="callout paddings elections_vote_against_wrap">
          <div
            title="Віддати голос проти всіх"
            class="button m-t elections_vote_against"
            @click="vote(null,0)"
            style="width: 100%;"
          >
            Віддати голос проти всіх
          </div>
        </div>
        <div v-if="status=='finished' && candidates.length>1" class="callout paddings elections_vote_against_wrap">
          <h5>Кількість голосів проти всіх: {{election.vote_against_all_count}}</h5>
        </div>
      </div>
      <div v-else>
        <b>Кандидати відсутні</b>
      </div>
  </div>
</template>

<script>
import appealsDetail from '@/components/appealsDetail.vue';
import API from '@/api.js';

export default {
  name: 'candidatesList',
  props:['election','status'],
  components:{
    appealsDetail,
  },
  data:function(){
    return {
      load:false
    }
  },
  computed:{
    candidates:function(){
      if(this.status=='finished'){
        let arr = this.election.candidates.map(c => {
          let appeals_impact_sum = 0;
          if(c.appeals.length){
            appeals_impact_sum = c.appeals.reduce((accumulator, a) => accumulator + a.impact, 0)
          }
          return {
            result_votes: c.vote_count - c.vote_against_count + appeals_impact_sum,
            ...c
          }
        })
        return arr.sort((a, b) => b.result_votes - a.result_votes);
      }

      return this.election.candidates;
    }
  },
  methods:{
    vote:function(candidate_id,vote){

      let api = new API(this.$store.state.key);

      let voteData = {
        election_id:this.election.id,
        candidate_id:candidate_id,
        vote:vote,
      }
      this.load=true;

      api.vote(voteData).then(() => {
        this.load=false;
        this.election.my_vote_count=1;
        window.location.hash="content-wrapper";//scroll
      }).catch((e) => {
        
        this.load=false;
        let error = 'Сервер не відповідає на запит ('+e.toString()+').';
        try{
          error = 'Сервер не відповідає на запит ('+e.data.data.description+').';
        }catch(e){}

        if(e.error=="bad response status"){
          if(e.data.data.description){
            error = e.data.data.description;
          }else{
            error = 'Сервер повернув неочікувану відповідь.'
          }
        }

        console.log(error)
        alert(error)
          
      })
    }
  }
}
</script>
